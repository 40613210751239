@import url('reset.scss');
@import url('fonts.css');

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

#root {
    position: relative;
    height: 100%;
    min-height: 100vh;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.fluid {
    width: 100%;
}

.hidden {
    display: none;
}
.hideOnDesktop {
    display: none;
    visibility: hidden;
}

.dangerouslyHTMLLink {
    color: #5780ea;
    text-decoration: none;
}
a {
    color: #5780ea;
    text-decoration: none;
}

@media screen and (max-width: 769px) {
    .hideOnDesktop {
        display: unset;
        visibility: unset;
    }

    .hideOnMobile {
        display: none !important;
        visibility: hidden;
    }
}
