/* -- Golos -- */
@font-face {
    font-family: 'Golos';
    src: local('Golos Regular'), local('Golos-Regular'),
        url('../assets/fonts/Golos/GolosText-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: local('Golos DemiBold'), local('Golos-DemiBold'),
        url('../assets/fonts/Golos/GolosText-DemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: local('Golos Bold'), local('Golos-Bold'),
        url('../assets/fonts/Golos/GolosText-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: local('Golos Black'), local('Golos-Black'),
        url('../assets/fonts/Golos/GolosText-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Golos';
    src: local('Golos Medium'), local('Golos-Medium'),
        url('../assets/fonts/Golos/GolosText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* -- Roboto -- */
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
